import auth, { getLoggedInUser, getTenant } from "cp-client-auth!sofe";
import { noop } from "lodash";
import { getAllLocalStorageFeatureToggles } from "feature-toggles!sofe";
import { captureMessage } from "error-logging!sofe";

export class CanopyError extends Error {}

export const getAbortController = () =>
  typeof AbortController !== "undefined"
    ? new AbortController()
    : { abort: noop, signal: null };

export const handleFetcherResponse = (
  fetcherPromise,
  responseType = "default",
  originalError
) =>
  fetcherPromise.then((response) => {
    if (response.ok) {
      if (response.status === 204) return null; // 204 No Content

      if (responseType === "default") {
        return response
          .text()
          .then((text) => {
            try {
              return JSON.parse(text);
            } catch (e) {
              return text;
            }
          })
          .catch(handleUnknownError);
      } else {
        return response[responseType]().catch(handleUnknownError);
      }
    } else {
      originalError.message = `Request error: ${response.status} ${response.url} ${response.statusText}`;
      originalError.status = response.status;
      // Try to get the response body and include it in the error
      return response
        .text()
        .then((body) => {
          originalError.message += `. ${body}`;
          try {
            originalError.data = JSON.parse(body);
          } catch (err) {
            originalError.data = body;
          }
          throw originalError;
        })
        .catch(() => {
          throw originalError;
        });
    }
  });

export function setHeaders(setHeader) {
  const csrfToken = auth.getCSRFToken();
  if (csrfToken) {
    setHeader("X-CSRF-TOKEN", csrfToken);
  }

  const user = getLoggedInUser();
  if (user) {
    setHeader("X-Canopy-User-ID", user.id);
  }

  const tenant = getTenant();
  if (tenant) {
    setHeader("X-Canopy-Tenant-ID", tenant.id);
  }

  const localStorageFeatureToggles = getAllLocalStorageFeatureToggles();
  if (Object.keys(localStorageFeatureToggles).length) {
    setHeader("X-Canopy-Overrides", JSON.stringify(localStorageFeatureToggles));
  }

  const backendOverride = window.localStorage.getItem("exchange-override");
  if (backendOverride) {
    setHeader("CTX-Exchange-Override", backendOverride);
  }

  setHeader(
    "client_app_name",
    window.appIsMobile ? "mobile-web-app" : "web-app"
  );
}

export function handleUnknownError(error) {
  if (error instanceof CanopyError) throw error;
  error.showToast = false;
  captureMessage(error);
  return Promise.reject(error);
}
