import { ReplaySubject, Observable, EMPTY, throwError } from "rxjs";
import { flatMap, map as observableMap, catchError } from "rxjs/operators";
import { transform, map, cloneDeep, noop } from "lodash";
import fetcher from "./fetcher.js";
import { handleFetcherResponse } from "./fetcher.helpers.js";

let cache = {};

export function isCached(url) {
  return !!cache[url];
}

// Returns a subset of the cache that have a base URL matching that of the given URL, but with a different query string
// These are items that are affected by a PUT/PATCH to the given URL but are not an exact match, & thus must handle their own updates (as opposed to using the PUT/PATCH response)
export function getAffectedCachedItems(url) {
  let baseUrl = url.split("?")[0];
  const baseIsClients = isClientsApi(baseUrl);

  return transform(cache, (result, value, key) => {
    const candidateUrl = key.split("?")[0];
    if (
      (key !== url && candidateUrl === baseUrl) ||
      (baseIsClients && isClientsApi(candidateUrl))
    ) {
      result[key] = value;
    }
  });
}

// TODO: Remove contacts when crm-refactor is complete
function isClientsApi(baseUrl) {
  return (
    /\/api\/contacts\/[0-9]+$/.test(baseUrl) ||
    /\/api\/clients\/[0-9]+$/.test(baseUrl)
  );
}

export function updateCache(key, data) {
  /* In rxjs, onNext happens synchronously so if any of the subscribers throw an error
   * inside of their subscribe functions, that error would cause the updateCache function
   * to throw an error. This is sort of lame, but, like many things, it can be solved
   * with a well-placed setTimeout. Doing so ensures that updateCache doesn't throw errors when
   * it's not really fetcher's fault.
   */
  if (!cache[key]) return;

  const { rxjs6Subject } = cache[key];
  setTimeout(() => {
    rxjs6Subject.next(data);
  });
}

export function fetchWithSharedCache() {
  // This is temporary while we migrate to rxjs@6
  const options = { rxjs6: true };
  return getWithSharedCache.apply(options, arguments);
}

export function getWithSharedCache(
  url,
  subscriptionDuration,
  forceBust = false
) {
  if (typeof url !== "string") {
    throw new Error(
      `getWithSharedCache must be called with a string url as its first parameter`
    );
  }

  const originalError = new Error();

  let buster;
  if (subscriptionDuration) {
    if (typeof subscriptionDuration === "function") {
      buster = subscriptionDuration;
    } else if (typeof subscriptionDuration === "string") {
      buster = () => window.location.href.indexOf(subscriptionDuration) < 0;
    } else {
      throw Error(
        `the subscriptionDuration argument to getWithSharedCache must be either a function or string. See https://canopy.githost.io/front-end/blue/fetcher#api`
      );
    }
  } else {
    throw Error(
      `getWithSharedCache must be called with a 'subscriptionDuration' argument. See https://canopy.githost.io/front-end/blue/fetcher#api`
    );
  }

  let rxjs6Subject;

  // url is in the cache
  if (cache[url]) {
    const cachedValue = cache[url];
    rxjs6Subject = cache[url].rxjs6Subject;

    // Add new cache buster
    if (typeof subscriptionDuration === "function") {
      cache[url].busters.push(subscriptionDuration);
    }

    if (_shouldBust(url) || forceBust) {
      // Make a new request and update the subject
      const fetcherPromise = fetcher(url);
      handleFetcherResponse(fetcherPromise, "json", originalError).catch(
        (err) => {
          const error = new Error(
            `Cannot automatically update fetcher cache for '${url}'. Server responded with http status ${err.status} and the response was ${err.data}`
          );
          error.data = err.data;
          error.status = err.status;
          rxjs6Subject.error(error);
        }
      );
    }

    // url is new to the cache
  } else {
    rxjs6Subject = new ReplaySubject(1);
    const busters = buster ? [buster] : [];
    const subscriptionDurationKey =
      typeof subscriptionDuration === "string" ? subscriptionDuration : null;
    const abortController =
      typeof AbortController !== "undefined"
        ? new AbortController()
        : { signal: null, abort: noop };
    _setCache(
      url,
      rxjs6Subject,
      busters,
      subscriptionDurationKey,
      abortController
    );

    const fetcherPromise = fetcher(url, { signal: abortController.signal });
    handleFetcherResponse(fetcherPromise, "json", originalError).catch(
      (err) => {
        rxjs6Subject.error(err);
      }
    );
  }

  let requestCanceled = false;

  function cleanupSharedCacheSubscription() {
    const totalNumObservers = rxjs6Subject.observers.length;

    if (totalNumObservers <= 1) {
      requestCanceled = true;
      abortRequest(url);
      delete cache[url];
    }
  }

  return Observable.create((observer) => {
    observer.next(null);
    return cleanupSharedCacheSubscription;
  }).pipe(
    flatMap(() => rxjs6Subject),
    observableMap(cloneDeep),
    catchError((err) => (requestCanceled ? EMPTY : throwError(err)))
  );
}

function abortRequest(url) {
  // Abort any network requests that no one cares about anymore
  const cachedValue = cache[url];
  if (cachedValue && cachedValue.abortController) {
    cachedValue.abortController.abort();
  }
}

/* Busts the cache for a url and re-fetches it so that all subscribers get the latest version of the object.
 * Returns a promise.
 */
export function forceBustCache(url) {
  if (typeof url !== "string") {
    throw new Error(
      `fetcher forceBustCache requires a url for which to bust the cache`
    );
  }

  const cacheBustPromises = map(getAffectedCachedItems(url), toCacheBustPromise) // Update the cache for all urls that are the same except for a different query string
    .concat(toCacheBustPromise(cache[url], url)); // Update the cache for this specific url too.

  return Promise.all(cacheBustPromises).catch((ex) => {
    throw ex;
  });

  function toCacheBustPromise(cache, affectedUrl) {
    return fetcher(affectedUrl).then((response) => {
      if (response.ok) {
        response
          .json() // Calling .json() is what actually causes the cache to be busted and all subscribers to be notified
          .catch((ex) => {
            throw ex;
          });
      } else {
        if (response.status === 404) {
          /* Sometimes doing a PUT/PATCH causes the server to respond with a 404 on subsequent GETs :(.
           * The example of this is archiving a client with a PATCH.
           */
          console.warn(
            `Cannot update fetcher cache for '${affectedUrl}' because server is now responding with a 404 (the server didn't used to respond with a 404)`
          );
        } else {
          throw response;
        }
      }
    });
  }
}

export function bustCacheForSubscriptionDuration(
  subscriptionDuration,
  refetchCachedEndpoints = false
) {
  let bustedCacheAlready = false;

  const bustCachePromises = Object.keys(cache)
    .filter(
      (cacheKeyUrl) =>
        cache[cacheKeyUrl].subscriptionDurationKey &&
        cache[cacheKeyUrl].subscriptionDurationKey === subscriptionDuration
    )
    .map((cacheKeyUrl) => {
      if (refetchCachedEndpoints && !bustedCacheAlready) {
        bustedCacheAlready = true; //forceBustCache already takes care of ALL cache items for this same URL, so we only need to call it once
        return forceBustCache(cacheKeyUrl);
      }

      if (bustedCacheAlready) {
        return Promise.resolve();
      }

      abortRequest(cacheKeyUrl);
      cache[cacheKeyUrl].rxjs6Subject.complete();
      delete cache[cacheKeyUrl];
      return Promise.resolve();
    });

  return Promise.all(bustCachePromises);
}

function _setCache(
  key,
  rxjs6Subject,
  busters,
  subscriptionDurationKey,
  abortController
) {
  if (!cache[key]) {
    cache[key] = {
      rxjs6Subject,
      busters,
      abortController,
    };

    if (subscriptionDurationKey) {
      cache[key]["subscriptionDurationKey"] = subscriptionDurationKey;
    }
  }
}

// Check if we need to bust the cache
function _shouldBust(key) {
  // Bust the cache if any of the item's cache busters return true
  return cache[key] && cache[key].busters
    ? cache[key].busters.some(_returnsTrue)
    : false;
}

function _returnsTrue(fn) {
  return !!fn();
}

window.addEventListener("hashchange", () => {
  for (let url in cache) {
    if (_shouldBust(url)) {
      cache[url].rxjs6Subject.complete();
      delete cache[url];
    }
  }
});
